import Pages from './pages'
import {
  PageRef,
  RouterData,
  RouterRef,
  DynamicRoute,
  SDKContext,
  SDKDefaultContext,
  ContextAwareOptions,
  AppData,
  RouterPrefixValidationObject,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  const routerOperationType = apiWrapper.OPERATION_TYPES.ROUTER
  const componentOperationType = apiWrapper.OPERATION_TYPES.COMP
  /**
   * @doc Routers
   * @description Adds a new router. A router allows the app to handle the routing of requests according to their URL prefixes. For example, a router can resolve a specific
   * incoming URL to a specific dynamic page that the Viewer renders. [Learn more](../articles/routers.md)
   * @example const animalsRouter = await editorSDK.document.routers.add('token', {prefix:'animals'});
   * @param token - app token - not in use
   * @param options -
   * - prefix: A string that determines which router will handle the incoming URL. A prefix must be unique. Use `isValidPrefix` to validate the prefix.
   * - config: The object that contains the data that the app can save on the router.
   * - group: A string that can group several routers together in the UI.
   * - appDefinitionId (required only in the Editor Extensions context): The unique ID of the application to add a router to.
   * @returns A promise that is resolved when the router is added. The return value is the routerRef that points to the added router.
   */
  function add(
    token: string,
    options: ContextAwareOptions<
      Context,
      {prefix: string; config?: object; group?: string},
      {appDefinitionId: string}
    >,
  ): Promise<RouterRef> {
    return apiWrapper.dsSetter(
      {
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.routers.add(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Returns router data for the specified router. An application can only get routers that belong to that app.
   * Otherwise the promise is resolved with *undefined*. [Learn more](../articles/routers.md)
   * @example const routerData = await editorSDK.document.routers.get('token', {routerRef});
   * @param token - app token - not in use
   * @param options -
   * - routerRef: A reference to the router whose data you want to return.
   * @returns A promise that resolves with the router data, consisting of:
   * - id: The router ID.
   * - prefix: A string containing the unique prefix, which determines which router handles the incoming URL.
   * - config: The object that holds the data that the app can save on the router.
   * - pages: An array of the *pagesRefs* and the *pageRoles* that belong to the router.
   */
  function get(
    token: string,
    options: {routerRef: RouterRef},
  ): Promise<RouterData> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.routerRef,
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.get(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Returns router data for each of the application's routers. [Learn more](../articles/routers.md)
   * @example const routersData = await editorSDK.document.routers.getAll('token');
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - appDefinitionId: The unique ID of the application whose routers you want to get.
   * @returns A promise that resolved with the application routers data. Returns an array of routerData:
   * - id: The router ID.
   * - prefix: A string containing the unique prefix, which determines which router handles the incoming URL.
   * - config: The object that contains the data that the app can save on the router.
   * - pages: An array of the *pagesRefs* and the *pageRoles* that belong to the router.
   */
  function getAll(
    token: string,
    options: ContextAwareOptions<Context, void, {appDefinitionId: string}>,
  ): Promise<RouterData[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.getAll(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Removes an application router. An application can remove only routers that were added by that app. [Learn more](../articles/routers.md)
   * @example editorSDK.document.routers.remove('token', {routerRef})
   * @param token - app token - not in use
   * @param options -
   * - routerRef: A reference to the router you want to remove.
   * @returns A promise that is resolved once the router is removed.
   */
  function remove(
    token: string,
    options: {routerRef: RouterRef},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.routerRef,
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.routers.remove(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Updates router data for the referenced router. [Learn more](../articles/routers.md)
   * @example editorSDK.routers.update('token', {routerRef, prefix:'animals', config: {field1: 'ffff'}})
   * @param token - app token - not in use
   * @param options -
   * - routerRef: A reference to the router you want to update.
   * - prefix: A unique string that determines which router handles the incoming URL.
   * - config: The object that contains the data that the app can save on the router.  The object may contain `resolvers`, `rules`, and `roleVariations` properties (used by the Editor Platform), which you cannot override.
   * @returns A promise that is resolved once the router data is updated.
   */
  function update(
    token: string,
    options: {
      routerRef: RouterRef
      prefix?: string
      config?: object
    },
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.routerRef,
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.routers.update(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Returns a router reference by *pageRef* if the *pageRef* refers to a dynamic page and belongs to the router. [Learn more](../articles/routers.md)
   * @example const routerRef = await editorSDK.document.routers.getByPage('token', {pageRef})
   * @param token - app token - not in use
   * @param options -
   * - pageRef: A reference to the  page whose router reference you want to return.
   * @returns A promise that is resolved with a reference to the router of the specified page. Return *undefined* if the pageRef
   * does not refer to a dynamic page or is not owned by the application.
   */
  function getByPage(
    token: string,
    options: {pageRef: PageRef},
  ): Promise<RouterRef> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.routers.getByPage(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Returns a reference to the router related to the specified prefix, if the prefix exists. Otherwise, returns *undefined*. [Learn more](../articles/routers.md)
   * @example const routerRef = await editorSDK.document.routers.getByPrefix('token', {prefix: 'animals'});
   * @param token - app token - not in use
   * @param options -
   * - prefix: A unique string that determines which router handles the incoming URL.
   *    @returns A  promise that is resolved with a reference to the router that is related to the specified prefix. Returns *undefined* if the prefix
   * does not exist or is not related to the application.
   */
  function getByPrefix(
    token: string,
    options: {prefix: string},
  ): Promise<RouterRef> {
    return apiWrapper.dsGetter(
      {
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.getByPrefix(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Returns an object describing whether the specified prefix is valid or not.
   * The prefix is valid when:
   *  - it isn't null or undefined.
   *  - contains only alphanumeric characters.
   *  - contains fewer than 40 characters.
   *  - is unique - no other prefixes with that name and no other pages with [pageURISEO](../articles/glossary.md#pageuriseo) with that name.
   *  [Learn more](../articles/routers.md)
   * @example const validObj = await editorSDK.document.routers.isValidPrefix('token', {prefix: 'animals'})
   * @param token - app token - not in use
   * @param options -
   * - prefix: A string containing the unique prefix, which determines which router handles the incoming URL.
   * - applicationId (Required only in Editor Extensions context): An ID of the application to check for validity of the router prefix.
   * @returns A promise that is resolved with the RouterPrefixValidationObject object indicating whether the prefix is valid or not. The validation object contains -
   * - errorCode: An int containing the error number.
   * - message: A string containing the error message.
   * - valid: A Boolean that is *true* if the prefix is valid.
   */
  function isValidPrefix(
    token: string,
    options: ContextAwareOptions<
      Context,
      {prefix: string},
      {applicationId: number}
    >,
  ): Promise<RouterPrefixValidationObject> {
    return apiWrapper.dsGetter(
      {
        operationTypes: [routerOperationType, componentOperationType],
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.isValidPrefix(appData, token, options),
    )
  }

  /**
   * @doc Routers
   * @description Asynchronously returns an object describing the current route in the case of a dynamic page. [Learn more](../articles/routers.md)
   * @param token - app token - not in use
   * @returns A promise that is resolved with the *DynamicRoute* object, which describes the current route:
   * - prefix: A string containing the unique prefix, which determines which router handles the incoming URL.
   * - innerRoute: A string that contains the route inside the router.
   * - routerId: A string that identifies the router.
   * If the page is not dynamic, returns *null*.
   */
  function getCurrentDynamicRouting(
    token: string,
  ): Promise<DynamicRoute | null> {
    return apiWrapper.dsGetter(
      {
        operationTypes: routerOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.routers.getCurrentDynamicRouting(),
    )
  }

  const InvalidPrefixReason = {
    PREFIX_CAN_NOT_BE_EMPTY: 1,
    PREFIX_IS_TOO_LONG: 2,
    PREFIX_IS_DUPLICATE_OF_URI_SEO: 3,
    PREFIX_CONTAINS_INVALID_CHARACTERS: 4,
    PREFIX_IS_FORBIDDEN_WORD: 5,
    PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION: 6,
  }

  return {
    add,
    get,
    getAll,
    remove,
    update,
    getByPage,
    getByPrefix,
    isValidPrefix,
    getCurrentDynamicRouting,
    InvalidPrefixReason,
    pages: Pages(appData),
  }
}
