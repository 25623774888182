import {
  getAPI,
  getAPIWithPlatformContext,
  getPlatformContext,
} from '../../privates/editorAPI'
import {
  PageRef,
  PageDefinition,
  PageData,
  DynamicPageLink,
  PageLink,
  SDKContext,
  SDKDefaultContext,
  ContextAwareOptions,
  AppData,
} from '@wix/editor-platform-sdk-types'
import Permissions from './permissions'
import PopupPages from './popupPages'
import Data from './data'
import PageUriSEO from './pageUriSEO'
import apiWrapper from '../../privates/apiWrapper'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Pages
   * @note `Classic Editor` `Editor X`
   * @description Navigates to static or dynamic page. When the Editor is in silent-install mode, navigation is not done, and this function returns *Promise.resolve()*.
   * @example await editorSDK.document.pages.navigateTo(token, {pageRef})
   * @example await editorSDK.document.pages.navigateTo(token, {pageLink: {type:  'PageLink', pageId: 'oy3rh'}})
   * @example await editorSDK.document.pages.navigateTo('', {pageLink: {type: 'DynamicPageLink', routerId: 'vqchm', innerRoute: 'that-dynamic-page'}})
   * @param token - app token - not in use
   * @param options -
   *  - `pageRef`: Page to navigate to.
   *  - `pageLink`: Same as <i>`pageRef`</i> just using different approach <i>(important: overrides `pageRef`)</i>
   * @returns A promise that is resolved when the navigation is complete.
   */
  function navigateTo(
    token: string,
    options: {pageRef: PageRef} | {pageLink: PageLink | DynamicPageLink},
  ): Promise<void> {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        return Promise.resolve()
      }
      return api.document.pages.navigateTo(appData, token, options)
    })
  }

  /**
   * @doc Pages
   * @description Rename the given page.
   * @example await editorSDK.document.pages.rename(token, {title: 'new name', pageRef: {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -
   *  - `title`: New page title
   *  - `pageRef`: Page to be renamed
   *  @returns A promise that is resolved on page name change.
   */
  function rename(
    token: string,
    options: {title: string; pageRef: PageRef},
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      getAPI().then((api) => {
        api.document.pages
          .rename(appData, token, options)
          .then((errorMessage) => {
            if (!errorMessage) {
              resolve()
              return
            }
            reject(errorMessage)
          })
      })
    })
  }

  /**
   * @doc Pages
   * @description Get current page ref. If a popup (lightbox) is open, the popup reference is returned.
   * @example const pageRef = await editorSDK.document.pages.getCurrent('token')
   * @param token - app token - not in use
   * @returns The current page ref.
   */
  function getCurrent(token: string): Promise<PageRef> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pages.getCurrent(appData, token),
    )
  }

  /**
   * @doc Pages
   * @description Get the primary page ref. If a popup (lightbox) is open, the reference to the page that is behind it is returned.
   * @example const pageRef = await editorSDK.document.pages.getPrimary('token')
   * @param token - app token - not in use
   * @returns The primary page ref.
   */
  function getPrimary(token: string): Promise<PageRef> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pages.getPrimary(appData, token),
    )
  }
  /**
   * @doc Pages
   * @note `Classic Editor` `Editor X`
   * @description Add a new page to the site.
   * @example
   *  const pageRef = await editorSDK.document.pages.add('token',  {
   *    title: 'new page', definition: pageDefinition, shouldAddMenuItem: false
   *  })
   * @param token - app token - not in use
   * @param options -
   *  - `title`: Page title
   *  - `definition`: Data to merge into new page definition <i>(Tip: for future platform managing add `{data: {managingAppDefId: '00000-0000-0000-0000-000000001'}}`)</i>
   *  - `shouldAddMenuItem`: Boolean value, set to *true* if the menu item linked to the added page should be added to main menu. Set to *false* if you don't want the new page to appear in the menu.
   *  - `shouldNavigateToPage`: Boolean value, set to *true* to navigate to the newly created page. The default value is *true*. When the Editor is in silent-install mode `shouldNavigateToPage` is set to *false*.
   * @returns A promise that is resolved with the new page ref.
   */
  function add(
    token: string,
    options: {
      title: string
      definition: PageDefinition
      shouldAddMenuItem: boolean
      shouldNavigateToPage?: boolean
    },
  ): Promise<PageRef> {
    return apiWrapper.dsSetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      async (api) => {
        const platformContext = await getPlatformContext(api)
        if (platformContext.isSilent) {
          options.shouldNavigateToPage = false
        }
        return api.document.pages.add(appData, token, options)
      },
    )
  }

  /**
   * @doc Pages
   * @description Gets the page's basic data.
   * @example const pageProperties = await editorSDK.document.pages.getPageData('token', {pageRef : {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: Page to get the data from.
   * @returns - A promise with the pages data
   */
  function getPageData(
    token: string,
    options: {pageRef: PageRef},
  ): Promise<PageData> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.pages.getPageData(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @description Checks if the given page is dynamic.
   * @example const isDynamicPage = await editorSDK.document.pages.isDynamicPage('token', {pageRef : {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options
   * - `pageRef`: Page to check to see if it's dynamic.
   * @returns A promise that is resolved with a Boolean, *true* if the page is dynamic, *false* if static.
   */
  function isDynamicPage(
    token: string,
    options: {pageRef: PageRef},
  ): Promise<boolean> {
    return getAPI().then((api) =>
      api.document.pages.isDynamicPage(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @description Gets all of the  pages that contain `managingAppDefId` property equal to  the *appDefinitionId* of the calling application.
   * @example const applicationPages = await editorSDK.document.pages.getApplicationPages('token')
   * @param token - app token - not in use
   * @param options:
   * - includeUnmanaged: The flag to include application pages without `managingAppDefId` property.
   * - appDefinitionId: The unique ID of the application whose pages you want to get (required only in Editor Extensions context) .
   * - applicationId: An ID of the application instance whose pages you want to get (required only in Editor Extensions context).
   * @returns - A promise that is resolved with the application's pages data.
   */
  function getApplicationPages(
    token: string,
    options: ContextAwareOptions<
      Context,
      {includeUnmanaged: boolean} | void,
      {appDefinitionId: string; applicationId: number}
    >,
  ): Promise<PageData[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pages.getApplicationPages(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @note `Classic Editor` `Editor X`
   * @description Remove the given page from  the site. Can only remove pages that contain `managingAppDefId` property equal to the *appDefinitionId* of the calling application.
   * @example await editorSDK.document.pages.remove('token', {pageRef : {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -.
   * - `pageRef`: The page to remove
   * - `pageToNavigateAfterRemove`: Page to navigate to after the removal (supported only in Classic Editor)
   * - `shouldShowEditorRemovePanel`: Boolean, set to *true* if popup for confirmation of page removal is shown in the editor (supported only in Classic Editor).
   * @returns A promise that is resolved after the page is removed.
   */
  function remove(
    token: string,
    options: {
      pageRef: PageRef
      pageToNavigateAfterRemove?: PageRef
      shouldShowEditorRemovePanel?: boolean
    },
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {compRefsToAwait: options.pageRef, operationTypes: compOperationType},
      (api) => api.document.pages.remove(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @description Return the home page PageRef.
   * @example const homePageRef = await editorSDK.document.pages.getHomePage('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the home page pageRef.
   */
  function getHomePage(token: string): Promise<PageRef> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pages.getHomePage(appData, token),
    )
  }

  /**
   * @doc Pages
   * @description Returns the page's serialized structure.
   * @example
   * const compStructure = await editorSDK.document.pages.serialize(token, {pageRef, maintainIdentifiers: true});
   * @param {string} token - app token, not in use
   * @param options -
   *  - `pageRef`: Page to serialize.
   *  - `maintainIdentifiers`: Boolean, set to *true* if the serialized component should contain IDs.
   * @returns A promise that is resolved with the component's serialized structure.
   */
  function serialize(
    token: string,
    options: {pageRef: PageRef; maintainIdentifiers?: boolean},
  ): Promise<any> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.pages.serialize(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @example
   * await editorSDK.document.pages.setState(token, {state: {
   *   private: [{type: 'DESKTOP', id: 'c1dmp'}, {type: 'DESKTOP', id: 'rjvq0'}]
   * }});
   * @param token - app token - not in use
   * @param options -
   * - `state`: A map of states to arrays of pageRefs.
   * @description Updates the state for one or more pages.
   *  Use this functionality to give a different editing experience to different pages
   *  by defining different page actions and settings in the appManifest.
   * @returns A promise that is resolved once the state has been updated.
   */
  function setState(
    token,
    options: {
      state: {
        [index: string]: PageRef[]
      }
    },
  ): Promise<void> {
    const compRefsToAwait = Object.keys(options.state)
      .map((key) => options.state[key])
      .reduce((acc, val) => acc.concat(val), [])
    return apiWrapper.dsUpdater(
      {compRefsToAwait, operationTypes: compOperationType},
      (api) => api.document.pages.setState(appData, token, options),
    )
  }

  /**
   * @doc Pages
   * @example
   * await editorSDK.document.pages.duplicate('token', { pageId: 'some-page', shouldAddMenuItem: true, shouldDuplicatePageCode: true });
   * @param token - app token - not in use
   * @param options -
   * - pageId: The ID of the page you want to duplicate.
   * - shouldAddMenuItem: Boolean that indicates whether to add an item to the pages panel for the new page.
   * - shouldDuplicatePageCode: Boolean that indicates whether to copy the Velo code of the page.
   * @description Duplicates a page.
   * @returns A promise that is resolved with the page ID and type after it's duplicated.
   */
  function duplicate(
    token,
    options: {
      pageId: string
      shouldAddMenuItem: boolean
      shouldDuplicatePageCode: boolean
    },
  ): Promise<{id: string; type: string; pageId: string}> {
    return getAPI().then((api) => {
      return api.document.pages.duplicate(appData, token, options)
    })
  }

  return {
    data: Data(appData),
    permissions: Permissions(appData),
    popupPages: PopupPages<Context>(appData),
    navigateTo,
    rename,
    add,
    duplicate,
    getCurrent,
    getPrimary,
    getPageData,
    getApplicationPages,
    remove,
    getHomePage,
    setState,
    serialize,
    pageUriSEO: PageUriSEO(appData),
    isDynamicPage,
  }
}
