import {getAPI} from '../privates/editorAPI'
import type {
  UserInfoObject as UserInfo,
  ComponentViewMode,
  SitemapEntry,
} from '@wix/document-services-types'
import {
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  AppInstancePayload,
} from '@wix/editor-platform-sdk-types'
import {isExtensionContext} from '../../utils/utils'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc Info
   * @description Returns the site's metaSite Id.
   * @example const metaSiteId = await editorSDK.document.info.getMetaSiteId('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string describing the site's metaSite Id.
   */
  function getMetaSiteId(token: string): Promise<string> {
    return getAPI().then((api) => {
      return api.document.info.getMetaSiteId(appData)
    })
  }

  /**
   * @doc Info
   * @description Returns the site's public URL.
   * @example const publicURL = await editorSDK.document.info.getPublicUrl('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing the site's public URL.
   */
  function getPublicUrl(token: string): Promise<string> {
    return getAPI().then((api) => {
      return api.document.info.getPublicUrl(appData)
    })
  }

  /**
   * @doc Info
   * @description Returns the site's Id.
   * @example const siteId = await editorSDK.document.info.getSiteId('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing the site's Id.
   */
  function getSiteId(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getSiteId(appData))
  }

  /**
   * @doc Info
   * @description Returns the site's current userId.
   * @example const userId = await editorSDK.document.info.getUserId('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing the userId.
   */
  function getUserId(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getUserId(appData))
  }

  /**
   * @doc Info
   * @description Returns the site's current user info.
   * @example const { name, email, isStudio } = await editorSDK.document.info.getUserInfo('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the UserInfo containing { name: string, email: string, isStudio: boolean }.
   */
  function getUserInfo(token: string): Promise<UserInfo> {
    return getAPI().then((api) => api.document.info.getUserInfo(appData))
  }

  /**
   * @doc Info
   * @description Returns the application appDefinitionId.
   * @example const appDefinitionId = await editorSDK.document.info.getAppDefinitionId('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing the appDefinitionId.
   */
  function getAppDefinitionId(token: string): Promise<string> {
    if (isExtensionContext(appData)) {
      throw new Error('appDefinitionId is meaningless in Extensions context')
    }

    return Promise.resolve(appData.appDefinitionId)
  }

  /**
   * @doc Info
   * @description Returns whether the site is published ('true') or not.
   * @example const isSitePublished = await editorSDK.document.info.isSitePublished('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with a Boolean - `true` if the site is published, `false` otherwise.
   */
  function isSitePublished(token: string): Promise<boolean> {
    return getAPI().then((api) => api.document.info.isSitePublished(appData))
  }

  function getEntityIdFromRef(...args) {
    const entityReference = args[args.length - 1]
    if (entityReference) {
      const isOfReferenceKind = entityReference.id && entityReference.type
      return isOfReferenceKind ? entityReference.id : null
    }
    return null
  }

  /**
   * @doc Info
   * @deprecated Use editorSDK.editor.environment.getLocale instead
   * @description Returns the editor's language code.
   * Editor's language defines the language of the User Interface of the Editor.
   * All Editor's panels should use this language.
   * @example const editorLanguageCode = await editorSDK.document.info.getLanguage('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing editor's language code.
   */
  function getLanguage(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getLanguage(appData))
  }

  /**
   * @doc Info
   * @description Returns the site's regional language code.
   * The site's regional language code defines the language of the site. Use to generate the content of the site in the language of the site.
   * It is set by the user in the Business Manager `Regional Settings` section.
   * @example const siteLanguageCode = await editorSDK.document.info.getRegionalLanguage('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the string containing the site's regional language code.
   */
  function getRegionalLanguage(token: string): Promise<string> {
    return getAPI().then((api) =>
      api.document.info.getRegionalLanguage(appData),
    )
  }

  /**
   * @doc Info
   * @description Returns *true* if the site has ever been saved, *false* otherwise.
   * > Tip: You can use this method to determine if the site is still a template.
   * @example const isSiteSaved = await editorSDK.document.info.isSiteSaved('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a Boolean value of *true* if the site has ever been saved, *false* otherwise.
   */
  function isSiteSaved(token: string): Promise<boolean> {
    return getAPI().then((api) => api.document.info.isSiteSaved(appData))
  }

  /**
   * @doc Info
   * @description Returns the current editorSDK version.
   * @example const editorSDKVersion = editorSDK.document.info.getSdkVersion('token')
   * @param token - app token - not in use
   * @returns An object containing the current SDK version and script source.
   */
  function getSdkVersion(token: string): {version: string; scriptSrc: string} {
    return (self as any).editorSDK && (self as any).editorSDK.sdkVersionInfo
  }

  /**
   * @doc Info
   * @description Returns the application instance as it appears on the clientSpecMap.
   * > Tip: You can use editorSDK.document.info.getAppInstancePayload('token') to get parsed payload from instance.
   * @example const instance = await editorSDK.document.info.getAppInstance('token')
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - applicationId: An ID of the application instance.
   * @returns A promise that is resolved with the string containing the application instance.
   */
  function getAppInstance(
    token: string,
    options: ContextAwareOptions<Context, void, {applicationId: number}>,
  ): Promise<string> {
    return getAPI().then((api) =>
      api.document.info.getAppInstance(appData, token, options),
    )
  }

  /**
   * @doc Info
   * @description Returns parsed payload from instance token.
   * @example const { instanceId } = await editorSDK.document.info.getAppInstancePayload('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the object containing information from instance's payload or null.
   */
  function getAppInstancePayload(
    token: string,
  ): Promise<AppInstancePayload | null> {
    return getAPI().then((api) =>
      api.document.info.getAppInstancePayload(appData, token),
    )
  }

  /**
   * @doc Info
   * @description Returns the [application instance id](../articles/glossary.md#application-instance-id) as it appears on the clientSpecMap.
   * @example const instanceId = await editorSDK.document.info.getAppInstanceId('token')
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - applicationId: An ID of the application instance.
   * @returns A promise that is resolved with the string containing the application instance id.
   */
  function getAppInstanceId(
    token: string,
    options: ContextAwareOptions<Context, void, {applicationId: number}>,
  ): Promise<string> {
    return getAPI().then((api) =>
      api.document.info.getAppInstanceId(appData, token, options),
    )
  }

  /**
   * @doc Info
   * @description Indicates if the site runs in HTTPS ('true').
   * @example const isHttpsEnabled = await editorSDK.document.info.isHttpsEnabled('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a Boolean indicating `true` if` the site runs in HTTPS.
   */
  function isHttpsEnabled(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.isHttpsEnabled(appData))
  }

  /**
   * @doc Info
   * @description Returns the site currency from the site settings.
   * @example const currency = await editorSDK.document.info.getCurrency('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a string describing the site currency.
   */
  function getCurrency(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getCurrency(appData))
  }

  /**
   * @doc Info
   * @description Returns the site time zone from the site settings.
   * @example const timeZone = await editorSDK.document.info.getTimeZone('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a string describing the site time zone, such as *America/Indiana/Indianapolis*.
   */
  function getTimeZone(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getTimeZone(appData))
  }

  /**
   * @doc Info
   * @description Returns the type of premium for the app, such as unlimited, pro, or basic.
   * @example
   * const ECOM_APP_DEF_ID = 'mock-app-def-id'
   * const theOtherAppDashboardUrl = await editorSDK.document.info.getVendorProductId(token, {
   *   appDefinitionId: ECOM_APP_DEF_ID
   * });
   * @param token - app token - not in use
   * @param options -
   *  - appDefinitionId - The other app's appDefinitionID. If appDefinitionId not specified returns the ID for the calling app. Required for Extensions context
   * @returns A promise to the vendorProductId.
   */
  function getVendorProductId(
    token: string,
    options: ContextAwareOptions<
      Context,
      {appDefinitionId: string} | void,
      {appDefinitionId: string}
    >,
  ): Promise<string> {
    return getAPI().then((api) =>
      api.document.info.getVendorProductId(appData, token, options),
    )
  }

  /**
   * @doc Info
   * @description Returns the site locale from the site settings.
   * @example const region = await editorSDK.document.info.getSiteRegion('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a string describing the site region.
   */
  function getSiteRegion(token: string) {
    return getAPI().then((api) => api.document.info.getRegion(appData))
  }

  /**
   * @doc Info
   * @description When provided with an array of article names returns an object containing each artifact name and matching artifact URL: *{name: {String}, url: {String}}*.
   * @example const artifacts = await editorSDK.document.info.getArtifactsUrls('token', ['artifactName1', 'artifactName2'])
   * @param token - app token - not in use
   * @param artifactsNames - An array with the names of the artifacts that you want to get.
   * @returns A promise that is resolved with an object containing {name: {String}, url: {String}} of each artifact you pass to the function.
   */
  function getArtifactsUrls(token: string, artifactsNames: Array<string>) {
    return getAPI().then((api) =>
      api.document.info.getArtifactsUrls(appData, artifactsNames),
    )
  }

  /**
   * @doc Info
   * @description Returns artifact overrides map from the site URL.
   * @example
   * const artifactOverrides = await editorSDK.document.info.getArtifactOverrides('token')
   *
   * // output example for js-platform-editor-sdk override (?js-platform-editor-sdk-override=https://localhost:8090/)
   * // {
   * //    'js-platform-editor-sdk-override': 'https://localhost:8090/'
   * // }
   * @param token - app token - not in use
   * @returns A promise that is resolved with an object containing artifact override names as keys and artifact override URLs as values.
   */
  function getArtifactOverrides(
    token: string,
  ): Promise<Record<string, string>> {
    return getAPI().then((api) => api.document.info.getArtifactOverrides())
  }

  /**
   * @doc Info
   * @description Returns app revision overrides map from the site URL.
   * @example
   * const appRevisionOverrides = await editorSDK.document.info.getAppRevisionOverrides('token')
   *
   * // output example for appRevisionOverride override (?appRevisionOverride=887726e7-bd55-4434-9f35-2416ff809207=20)
   * // {
   * //    '887726e7-bd55-4434-9f35-2416ff809207': 20
   * // }
   * @param token - app token - not in use
   * @returns A promise that is resolved with an object containing app revision overrides where appIds are keys and site revisions are values.
   */
  function getAppRevisionOverrides(
    token: string,
  ): Promise<Record<string, number>> {
    return getAPI().then((api) => api.document.info.getAppRevisionOverrides())
  }

  /**
   * @doc Info
   * @description Returns the current site name.
   * @example const name = await editorSDK.info.getSiteDisplayName() // "Mysite 11"
   * @returns A promise that is resolved with a string describing the site name.
   */
  function getSiteDisplayName(): Promise<string> {
    return getAPI().then((api) => api.document.info.getSiteDisplayName())
  }

  /**
   * @doc Info
   * @description Returns the current editor stage view mode, which can be either
   * preview* or *editor*.
   * @example
   * const stageViewMode = await editorSDK.document.info.getStageViewMode()
   * // stageViewMode is either *preview* or *editor*.
   * @returns A promise that is resolved with a string describing the current stage view mode.
   */
  function getStageViewMode(): Promise<ComponentViewMode> {
    return getAPI().then((api) => api.document.info.getStageViewMode())
  }

  /**
   * @doc Info
   * @description Gets the sitemap entries.
   * @example
   * const siteMap = await editorSDK.document.info.getSiteMap(token)
   * @param token - app token - not in use
   * @returns A promise that is resolved with an array of sitemap entries.
   */
  function getSiteMap(token): Promise<SitemapEntry> {
    return getAPI().then((api) => api.document.info.getSiteMap(appData, token))
  }

  /**
   * @doc Info
   * @description Returns the site's current GEO (user geographic location).
   * @example const userGEO = await editorSDK.document.info.getUserGEO('token');
   * @param token - app token - not in use
   * @returns A promise that is resolved with the User GEO.
   */
  function getUserGEO(token: string): Promise<string> {
    return getAPI().then((api) => api.document.info.getUserGEO(appData))
  }

  return {
    getMetaSiteId,
    getPublicUrl,
    getSiteId,
    getUserId,
    getUserInfo,
    getAppDefinitionId,
    isSitePublished,
    getEntityIdFromRef,
    getLanguage,
    getRegionalLanguage,
    isSiteSaved,
    isHttpsEnabled,
    getSdkVersion,
    getCurrency,
    getTimeZone,
    getAppInstance,
    getAppInstancePayload,
    getAppInstanceId,
    getVendorProductId,
    getSiteRegion,
    getArtifactsUrls,
    getArtifactOverrides,
    getSiteDisplayName,
    getStageViewMode,
    getSiteMap,
    getUserGEO,
    getAppRevisionOverrides,
  }
}
