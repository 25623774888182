import {
  PageRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  PopupPageDefinition,
  PopupData,
  ComponentRef,
  PopupPageBehavior,
} from '@wix/editor-platform-sdk-types'
import {getAPI, getAPIWithPlatformContext} from '../../privates/editorAPI'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc PopupPages
   * @description Add new popup to site.
   * @example const popupRef = await editorSDK.document.pages.popupPages.add('token', { title: 'new popup', definition: {data: {managingAppDefId: '00000-0000-0000-0000-000000001'}}})
   * @param token - app token - not in use
   * @param options -
   *  - title: The popup page's title.
   *  - definition: Optional. The data to merge into the new page's definition. If no definition is passed the popup will have a default structure and data. <i>(Tip: for future platform managing add `{data: {managingAppDefId: '00000-0000-0000-0000-000000001'}}`)</i>
   *  - shouldNavigateToPage: Boolean value that when set to *true* (default) indicates to the site to navigate to the newly created page. When the Editor is in silent-install mode `shouldNavigateToPage` is set to *false*.
   * @returns a `Promise` that is resolved with the new popup ref
   */
  function add(
    token: string,
    options: {
      title: string
      definition: Partial<PopupPageDefinition>
      shouldNavigateToPage?: boolean
    },
  ): Promise<PageRef> {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        options.shouldNavigateToPage = false
      }
      return api.document.pages.popupPages.add(appData, token, options)
    })
  }

  /**
   * @doc PopupPages
   * @description Adds a new popup page to a site, including a controller connected to its root container. This turns the container into a [connected component](../articles/controllers.md#connections), enabling the app to have full control over the popup page.
   * For example, the app can override this page's GFPP.
   * @example const connectedPopupRef = await editorSDK.document.pages.popupPages.addConnected('token', { title: 'new popup', definition: {data: {managingAppDefId: '00000-0000-0000-0000-000000001'}}, controllerType: 'niceController', popupRole: 'popupContainer'})
   * @param token - app token - not in use
   * @param options -
   *  - title: The popup page's title.
   *  - definition: The data to merge into the new page's definition. If no definition is passed the popup will have a default structure and data. <i>(Tip: for future platform managing add `{data: {managingAppDefId: '00000-0000-0000-0000-000000001'}}`)</i>
   *  - controllerType: The type of controller to create.
   *  - popupRole: Role add to the connected popup container.
   *  - shouldNavigateToPage: Boolean value that when set to *true* (default) indicates to the site to navigate to the newly created page. When the Editor is in silent-install mode `shouldNavigateToPage` is set to *false*.
   *  - appDefinitionId (Required only in Editor Extensions context): The unique ID of the application to add the connected popup to.
   * @returns A promise that is resolved with a reference to the new popup age once the controller is added and the connection is made.
   */
  function addConnected(
    token: string,
    options: ContextAwareOptions<
      Context,
      {
        title: string
        definition: Partial<PopupPageDefinition>
        controllerType: string
        popupRole: string
        shouldNavigateToPage?: boolean
      },
      {appDefinitionId: string}
    >,
  ): Promise<PageRef> {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        options.shouldNavigateToPage = false
      }
      return api.document.pages.popupPages.addConnected(appData, token, options)
    })
  }

  /**
   * @doc PopupPages
   * @description Open popup
   * @example await editorSDK.document.pages.popupPages.open('token', { popupRef: {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -
   *   - `popupRef`: Popup reference
   * @returns a `Promise` that is resolved when popup is opened, When the Editor is in silent-install mode, navigation is not done, and this function returns *Promise.resolve()*.
   */
  function open(token: string, options: {popupRef: PageRef}): Promise<void> {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      if (platformContext.isSilent) {
        return Promise.resolve()
      }
      return api.document.pages.popupPages.open(appData, token, options)
    })
  }

  /**
   * @doc PopupPages
   * @description Closes an open popup, if it exists.
   * @example await editorSDK.document.pages.popupPages.close('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved when the popup is closed.
   */
  function close(token: string): Promise<void> {
    return getAPI().then((api) =>
      api.document.pages.popupPages.close(appData, token),
    )
  }

  /**
   * @doc PopupPages
   * @description Returns *true* if a popup is open, *false* otherwise.
   * @example
   * const popupOpened = await editorSDK.document.pages.popupPages.isPopupOpened('token')
   * if (popupOpened) {
   *     await editorSDK.document.pages.popupPages.close('token')
   * }
   * @param token - app token - not in use
   * @returns A Boolean that indicates if a popup is open (*true*).
   */
  function isPopupOpened(token: string): Promise<boolean> {
    return getAPI().then((api) =>
      api.document.pages.popupPages.isPopupOpened(appData, token),
    )
  }

  /**
   * @doc PopupPages
   * @description Gets all popups data objects that have a *managingAppDefId* property that equals the *appDefinitionId* of the calling application.
   * @example const applicationPopups = await editorSDK.document.pages.popupPages.getApplicationPopups('token')
   * @param token - app token - not in use
   * @param options (ERequired only in Editor Extensions context):
   * - appDefinitionId: The unique ID of the application whose popup pages you want to get.
   * @returns An array of data objects that contains application popups (*managingAppDefId* property equal the *appDefinitionId*).
   */
  function getApplicationPopups(
    token: string,
    options: ContextAwareOptions<Context, void, {appDefinitionId: string}>,
  ): Promise<PopupData[]> {
    return getAPI().then((api) =>
      api.document.pages.popupPages.getApplicationPopups(
        appData,
        token,
        options,
      ),
    )
  }

  /**
   * @doc PopupPages
   * @description Updates the behavior of a popup page (lightbox).
   * @example
   * await editorSDK.document.pages.popupPages.updateBehavior('token', {
   *  popupRef: {id: 'oy3rh', type: 'DESKTOP'},
   *  behavior: {
   *    name: 'openPopup',
   *    params: {
   *      delay: 10,
   *      openInDesktop: true,
   *      openInMobile: true,
   *    }
   *  }
   * })
   * @param token - app token - not in use
   * @param options -
   *   - `popupRef`: Popup reference.
   *   - `behavior`: Popup page behavior to apply.
   * @returns A promise that is resolved when the behavior is updated.
   */
  function updateBehavior(
    token: string,
    options: {
      popupRef: ComponentRef
      behavior: PopupPageBehavior
    },
  ): Promise<void> {
    return getAPI().then((api) => {
      api.document.pages.popupPages.updateBehavior(appData, token, options)
    })
  }

  return {
    add,
    addConnected,
    open,
    close,
    isPopupOpened,
    getApplicationPopups,
    updateBehavior,
  }
}
