import * as pmrpc from 'pm-rpc'
import {getAPI, getAPIWithPlatformContext} from '../../privates/editorAPI'
import {isWorker, getApiName, API_TYPES} from '../../privates/utils'
import {getAppExportedApis, reloadAppManifest} from '../../privates/workerSdk'
import {getEditorOriginType} from '../../privates/originService'
import LivePreview, {livePreviewRefreshOptions} from './livePreview'
import SessionState, {updateSessionStateOptions} from './sessionState'
import {
  ComponentRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  PageRef,
  ApplicationInstallOptions,
  ApplicationUniversalInstallOptions,
  ApplicationInstalledOptions,
  addWidgetOptions,
  ChangePresetOptions,
  DesignVariables,
  GetDesignVariablesOptions,
  SetDesignVariablesOptions,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'
import type {AppDataTokenOptions} from '../../../utils/utils'

const compOperationType = apiWrapper.OPERATION_TYPES.COMP
type changeVariationOptions = {
  componentRef: ComponentRef
  variationId: string
  keepOverrides: boolean
}

type uninstallOptions<Context extends SDKContext = SDKDefaultContext> =
  ContextAwareOptions<
    Context,
    {openConfirmation: boolean},
    {appDefinitionId: string}
  >

type registerToCustomEventsOptions<
  Context extends SDKContext = SDKDefaultContext,
> = ContextAwareOptions<
  Context,
  {eventTypes: string[]},
  {applicationId: number}
>

type getPresetOptions = {
  componentRef: ComponentRef
}

export type getPresetResult = {
  type: 'PresetData'
  style?: string
  layout?: string
}

type getPropsOptions = {
  widgetRef: ComponentRef
}

export type getPropsResult = Record<string, unknown>

type setPropsOptions = {
  widgetRef: ComponentRef
  newProps: Record<string, unknown>
  shouldFetchData?: boolean
}

/**@hidden**/
export interface IDocumentApplication {
  install: AppDataTokenOptions<ApplicationInstallOptions, Promise<object>>
  uninstall: AppDataTokenOptions<uninstallOptions, Promise<void>>
  registerToCustomEvents: AppDataTokenOptions<
    registerToCustomEventsOptions,
    Promise<void>
  >
  appStudioWidgets: {
    changeVariation: AppDataTokenOptions<
      changeVariationOptions,
      Promise<object>
    >
    changePreset: AppDataTokenOptions<ChangePresetOptions, Promise<void>>
    getPreset: AppDataTokenOptions<getPresetOptions, Promise<getPresetResult>>
    addWidget: AppDataTokenOptions<addWidgetOptions, Promise<object>>
    props: {
      get: AppDataTokenOptions<getPropsOptions, Promise<getPropsResult>>
      set: AppDataTokenOptions<setPropsOptions, Promise<void>>
    }
    designVariables: {
      get: AppDataTokenOptions<
        GetDesignVariablesOptions,
        Promise<DesignVariables>
      >
      set: AppDataTokenOptions<SetDesignVariablesOptions, Promise<void>>
    }
  }
  livePreview: {
    refresh: AppDataTokenOptions<livePreviewRefreshOptions, Promise<void>>
  }
  sessionState: {
    update: AppDataTokenOptions<updateSessionStateOptions, Promise<void>>
  }
}

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc Application
   * @description Allows you to get the public API of another Editor application using the application's appDefinitionId.
   * In order to expose a public API you need to return the functions you want to expose in the exports object of your Editor script. [Learn more](../articles/app-exported-apis.md#public-api)
   * @example const publicApi = await editorSDK.document.application.getPublicAPI('token', {appDefinitionId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'});
   * @param {string} token - app token, not in use
   * @param options -
   * - appDefinitionId: The application's appDefinitionId.
   * @returns If called from application panels and the requested API has been set by the application, returns a promise that resolves with an object containing the functions describing the application's public API.
   * If called from application panels and the requested API has not been set by the application, returns a rejected promise.
   * If called from the worker and the requested API has been set by the application, returns functions describing the application's public API, without a promise.
   * If called from the worker and the requested API has not been set by application, returns *undefined*.
   */
  function getPublicAPI(
    token: string,
    options: {appDefinitionId: string},
  ): Promise<object> {
    if (isWorker()) {
      const apis = getAppExportedApis(options.appDefinitionId) || {}
      return apis[API_TYPES.PUBLIC]
    }

    return pmrpc.api.request(
      getApiName(options.appDefinitionId, API_TYPES.PUBLIC),
      {
        target: parent,
      },
    )
  }

  /**
   * @doc Application
   * @description The application reloads the [App Manifest](../articles/manifest-intro.md) by calling `getAppManifest` again and sets the new manifest result.
   * @example editorSDK.document.application.reloadManifest();
   * @returns  A promise that is resolved once the manifest is reloaded.
   */
  function reloadManifest(): Promise<void> {
    if (isWorker()) {
      return reloadAppManifest(appData)
    }
    return Promise.reject(
      new Error('reloadManifest can only be called from worker'),
    )
  }

  /**
   * @doc Application
   * @description Register to custom Editor events. The registered events can then be listened to in the exported `onEvent` function.
   * Use of `onEvent` is deprecated. Use [addEventListener](../Editor/EventTarget.md#addeventlistener), which calls `registerToCustomEvents` events for you.
   * @example editorSDK.document.application.registerToCustomEvents(token, {eventTypes: ['anyComponentAddedToStage', 'componentDataChanged']});
   * @param {string} token - app token, not in use
   * @param options -
   *  - `eventTypes` - The list of event types to register.
   *  - `applicationId` (Required only in Editor Extensions context): An ID of the application which is registering for custom events.
   * @returns  A promise that resolves when the events are registered.
   */
  function registerToCustomEvents(
    token,
    options: registerToCustomEventsOptions<Context>,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.document.application.registerToCustomEvents(appData, token, options),
    )
  }

  /**
   * @doc Application
   * @deprecated Use `editorSDK.document.application.add('token', { appDefinitionId, componentTypes: ['PLATFORM'] })` to provision the platform part of the app.
   * @note `Classic Editor` `Editor X`
   * @description Allows your application to install another platform app (or platform part of an app) by specifying the other app's *appDefinitionId*. The installation
   * consists of provisioning the application and adding it to a site's *clientSpecMap*.
   * @example editorSDK.document.application.install('token', {
   *                        appDefinitionId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
   *                        originInfo: {
   *                               customPropertyA: 'a',
   *                               customPropertyB: 'b'
   *                           }
   *                        })
   * @param {string} token - app token, not in use
   * @param options -
   * - appDefinitionId: The application's appDefinitionId.
   * - originInfo: Additional information that an application passes to the installed application in the `origin.info` object.
   * - sourceTemplateId: The origin metaSite ID from which to clone the app data. Required when installing an application without adding components, for example, when installing an app that can provision with different presets.
   * - isSilent: If set to *true*, the application is installed in silent mode, without prompts or navigations.
   * @returns  A promise that resolves when the application is provisioned with the application data, and is rejected if the provisioning failed.
   */
  function install(
    token: string,
    options: ApplicationInstallOptions,
  ): Promise<object> {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      const fullOptions = {
        ...options,
        editorType: getEditorOriginType(),
      }
      if (platformContext.isSilent) {
        fullOptions.isSilent = true
      }

      return api.document.application.install(appData, token, fullOptions)
    })
  }

  /**
   * @doc Application
   * @note `Classic Editor` `Editor X`
   * @description A universal method to add a new application of any type (platform app, TPA) to the site.
   * @example editorSDK.document.application.add('token', {
   *                        appDefinitionId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
   *                        originInfo: {
   *                               customPropertyA: 'a',
   *                               customPropertyB: 'b'
   *                           }
   *                        })
   * @param {string} token - app token, not in use
   * @param options -
   * - appDefinitionId: The application's appDefinitionId.
   * - originInfo: Additional information that an application passes to the installed application in the `origin.info` object.
   * - sourceTemplateId: The origin metaSite ID from which to clone the app data. Required when installing an application without adding components, for example, when installing an app that can provision with different presets.
   * - isSilent: If set to *true*, the application is installed in silent mode, without prompts or navigations.
   * - managingAppDefId: The appDefinitionId of the app that can manage the pages added by this function. For example, the ID of *Members*, which can manage other apps' pages. Works for Section Apps only.
   * - showPageAddedPanel: If set to *true*, shows the progress bar for the application installation process. Works for Section Apps only.
   * - disableAddPanel: If set to *true*, doesn't open the Add Panel for an application with category definition after the installation. Works for Section Apps only.
   * - shouldNavigate: If set to *true*, navigates to the main application page after the installation. Works for Section Apps only.
   * - componentTypes: The specific parts of an application to be installed. If not provided, The entire application is installed. Currently, only the 'PLATFORM' part is supported.
   * @returns  A promise that resolves when the application is provisioned with the application data, and is rejected if the provisioning failed.
   */
  function add(
    token: string,
    options: ApplicationUniversalInstallOptions,
  ): Promise<
    | {instanceId: string}
    | {
        instanceId: string
        pageRef: PageRef
        pageUriSEO: string
        title: string
      }
  > {
    return getAPIWithPlatformContext().then(({api, platformContext}) => {
      const fullOptions = {
        ...options,
        editorType: getEditorOriginType(),
      }
      if (platformContext.isSilent) {
        fullOptions.isSilent = true
        fullOptions.shouldNavigate = false
        fullOptions.disableAddPanel = true
        fullOptions.showPageAddedPanel = false
      }

      return api.document.tpa.add.application(appData, token, fullOptions)
    })
  }

  /**
   * @doc Application
   * @note `Classic Editor` `Editor X`
   * @description Removes the app that calls this function. Also removes pages, widgets, components, routers, and menus installed by the app.
   * Does not remove data or Velo code written by the user. Does not remove other apps that were installed by the calling app at the time of installation.
   * (Some refer to this as *delete* or *remove*.)
   * [Learn more](../articles/uninstall.md).
   * @example editorSDK.application.uninstall('token', {openConfirmation: true})
   * @param {string} token - app token, not in use
   * @param options -
   * - openConfirmation: When set to *true* (default), opens a modal panel to ask the user to confirm that they want to delete the app.
   * - appDefinitionId (Required only in Editor Extensions context): The unique ID of the application to uninstall.
   * @returns A promise that resolves when the application and all of its related elements have been removed.
   */
  function uninstall(
    token: string,
    options: uninstallOptions<Context>,
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.application.uninstall(appData, token, options)
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Allows you to change the widget variation for widgets built using Wix Blocks (App Builder).
   * > **Note**: This method is used only in the Blocks Alpha version. Blocks apps now use the widget design [preset panel](../articles/widget-design-manifest.md) instead.
   * @example editorSDK.document.application.appStudioWidgets.changeVariation('token', {componentRef: {id: 'comp-jziidxoy', type: 'DESKTOP'}, variationId: 'rjvq0', keepOverrides: false})
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A pointer to the widget whose variation you want to change.
   * - variationId: The pageId of the variation you want to switch to.
   * - keepOverrides: Applies to user data and style overrides. Default value is *true* (keep overrides). Set to *false* to reset overrides.
   * @returns A promise that resolves with the compRef (different from the passed ComponentRef when the widget was open).
   */
  function changeVariation(
    token: string,
    options: changeVariationOptions,
  ): Promise<object> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.changeVariation(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Allows you to change the widget preset for widgets built using Responsive Wix Blocks (App Builder). [Learn more](../articles/variations.md)
   > **Note**: An application can only change the presets of its own widgets.
   * @example editorSDK.document.application.appStudioWidgets.changePreset('token', {componentRef: {id: 'comp-jziidxoy', type: 'DESKTOP'}, stylePresetId: '#variants-kjsgpua8', layoutPresetId: '#variants-kjsgpua9'})
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A pointer to the widget whose preset you want to change.
   * - stylePresetId: The variantId of the preset you want to switch the style to.
   * - layoutPresetId: The variantId of the preset you want to switch the layout to.
   * - context: { viewport: 'DESKTOP' | 'TABLET' | 'MOBILE' } An optional param that controls in the context in which the new preset is applied. The only supported context type is 'viewport', which is a general name for Desktop / Mobile views in the classic Wix Editor or breakpoints in Editor X. The 'TABLET' value of the 'viewport' property is only supported in EditorX. In the classic Wix Editor it will throw an error.
   * @returns A promise that resolves when the preset is changed.
   */
  function changePreset(
    token: string,
    options: ChangePresetOptions,
  ): Promise<void> {
    return getAPI().then(async (api) => {
      const widgetData = await api.document.components.data.get(
        appData,
        token,
        {
          componentRef: options.componentRef,
        },
      )
      if (appData?.appDefinitionId !== widgetData?.appDefinitionId) {
        throw new Error(
          "Can not change the preset of another application's widget",
        )
      }
      return api.document.application.appStudioWidgets.changePreset(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Allows you to get the widget preset for widgets built using Responsive Wix Blocks (App Builder). [Learn more](../articles/variations.md)
   * @example editorSDK.document.application.appStudioWidgets.getPreset('token', {componentRef: {id: 'comp-jziidxoy', type: 'DESKTOP'} })
   * @param {string} token - app token, not in use
   * @param options -
   * - componentRef: A pointer to the widget whose preset you want to change.
   * @returns A promise that resolves with the preset data.
   */
  function getPreset(
    token: string,
    options: getPresetOptions,
  ): Promise<getPresetResult> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.getPreset(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Gets the properties part of the public API of a given Wix Blocks widget
   * @example
   * editorSDK.application.appStudioWidgets.props.get('token', {widgetRef: {id: 'comp-jziidxoy', type: 'DESKTOP'}})
   * @param {string} token - app token, not in use
   * @param options -
   * - widgetRef: A pointer to the widget component whose properties you want to get.
   * @returns  A promise that resolves to the properties object
   */
  function getProps(
    token: string,
    options: getPropsOptions,
  ): Promise<getPropsResult> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.props.get(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Sets the properties part of the public API of a given Wix Blocks widget and calls [`livePreview.refresh()`](./Application-LivePreview.md#refresh).
   * The current widget properties are overwritten by properties in _newProps_ if they have the same key.
   * To clear the widget properties, pass undefined in _newProps_.
   * @example
   * editorSDK.application.appStudioWidgets.props.set('token', {widgetRef: {id: 'comp-jziidxoy', type: 'DESKTOP'}, newProps: {existingProp: 'newValue', newProp: 123}})
   * @param {string} token - app token, not in use
   * @param options -
   * - widgetRef: A pointer to the widget component whose properties you want to set.
   * - newProps: A JSON object with the properties
   * - shouldFetchData: This options is passed to `livePreview.refresh()` and is a flag that is passed to `createControllers` indicating whether the refresh requires the Viewer app to re-fetch the app data.
   * @returns A promise that resolves with void
   */
  function setProps(token: string, options: setPropsOptions): Promise<void> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.props.set(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Adds a widget built in Wix Blocks (App Builder) to the site.
   * @example
   * const widgetPageId = 'widgetPageId'
   * const variationId = 'variationId'
   * const layout = {
   *   height: 55,
   *   width: 55,
   *   x: 55,
   *   y: 55,
   * }
   * editorSDK.application.appStudioWidgets.addWidget('token', {widgetId: widgetPageId, variationId, installationType: 'open', layout})
   * @param {string} token - app token, not in use
   * @param options -
   * - widgetId: The Dev Center ID of the requested widget.
   * - variationId: An optional pageId of the requested widget variation.
   * - scopedPresets: An optional mapping of which preset to assign to the widget for desktop, tablet and mobile viewports.
   * - containerRef: An optional componentRef of the container to add the widget to. Default is current page.
   * - layout: Optional layout overrides to apply to the widget. If not provided, the widget is added to center stage.
   * - installationType: Controls whether the widget is added by-ref (closed), or as an open structure.
   * - appDefinitionId (Required only in Editor Extensions context): The unique ID of the application on which to install the widget.
   * @returns A promise that resolves with the compRef of the newly-added widget - a RefComponent if closed, and an AppWidget if open.
   */
  function addWidget(
    token: string,
    options: addWidgetOptions<Context>,
  ): Promise<ComponentRef> {
    return apiWrapper.dsSetter(
      {
        compRefsToAwait: options.containerRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.application.appStudioWidgets.addWidget(
          appData,
          token,
          options,
        ),
    )
  }

  /**
   * @doc Application
   * @description Returns whether the app is installed and running.
   * @example
   * editorSDK.application.isApplicationInstalled('token', {appDefinitionId: '7cc9c558-ae0a-494c-858e-8f7e2df90ed8'})
   * @param {string} token - app token, not in use
   * @param options -
   * - appDefinitionId: The unique ID of the application to check if active.
   * @returns A promise that resolves with a Boolean that is _true_ if the app is installed and running.
   */
  function isApplicationInstalled(
    token: string,
    options: ApplicationInstalledOptions,
  ): Promise<boolean> {
    return getAPI().then((api) =>
      api.document.application.isApplicationInstalled(appData, token, options),
    )
  }

  /**
   * @doc AppStudioWidgets
   * @description Gets the design variables for widgets built using Responsive Wix Blocks (App Builder).
   * @example editorSDK.document.application.appStudioWidgets.designVariables.get('token', {widgetRef: {id: 'comp-jziidxoy', type: 'DESKTOP'} })
   * @param {string} token - app token, not in use
   * @param options -
   * - widgetRef: A pointer to the widget whose design variables you want to change.
   * @returns A promise that resolves with the design variables and their values. ({designVariable1: {value: 10, unit: 'px'}, numberVar: 4, alignmentVar: 'center'})
   */
  function getDesignVariables(
    token: string,
    options: GetDesignVariablesOptions,
  ): Promise<DesignVariables> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.designVariables.get(
        appData,
        token,
        options,
      )
    })
  }

  /**
   * @doc AppStudioWidgets
   * @description Change the values of design variables for widgets built using Responsive Wix Blocks (App Builder).
   * @example
   * editorSDK.document.application.appStudioWidgets.designVariables.set('token',
   *                                                                      {widgetRef: {id: 'comp-jziidxoy', type: 'DESKTOP'},
   *                                                                       newValues: {designVariable1: {value: 10, unit: 'px'}, numberVar: 4, alignmentVar: 'center'}})
   * @param {string} token - app token, not in use
   * @param options -
   * - widgetRef: A pointer to the widget whose design variables you want to set.
   * - newValues: A JSON object with the values of design variables
   * @returns A promise that resolves with void
   */
  function setDesignVariables(
    token: string,
    options: SetDesignVariablesOptions,
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.application.appStudioWidgets.designVariables.set(
        appData,
        token,
        options,
      )
    })
  }

  return {
    getPublicAPI,
    reloadManifest,
    add,
    install,
    uninstall,
    isApplicationInstalled,
    registerToCustomEvents,
    appStudioWidgets: {
      changeVariation,
      changePreset,
      getPreset,
      addWidget,
      props: {
        get: getProps,
        set: setProps,
      },
      designVariables: {
        get: getDesignVariables,
        set: setDesignVariables,
      },
    },
    sessionState: SessionState(appData),
    livePreview: LivePreview<Context>(appData),
  }
}
