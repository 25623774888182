import editorSDK from './editorSDK'
import {isWebWorker, isMainFrame, isNodeWorker} from './privates/utils'
import {setEditorOriginType} from './privates/originService'
import {setCommonConfig} from './privates/commonConfigService'
import * as workerSdk from './privates/workerSdk'
import {initEditor} from './privates/editorSdk'
import {getQueryParameter} from './privates/urlUtils'
import {SDK_CONTEXT} from '../utils/consts'
import {initReceiver} from '../api/events/propagation'
import {initRPC} from './privates/pmRpcUtils'

let extraNamespaces

export const __init = (
  reloadManifestMethod,
  extraNamespacesArg = '',
  editorType = '',
  commonConfig = '',
) => {
  extraNamespaces = extraNamespacesArg.split(',')
  setEditorOriginType(editorType)
  setCommonConfig(commonConfig)
  return workerSdk.initWorker(reloadManifestMethod)
}

export const __initWithTarget = async (
  target,
  extraNamespacesArg = [],
  editorType = '',
  commonConfig = '',
) => {
  await initRPC('editorAPI', target)
  extraNamespaces = extraNamespacesArg
  setEditorOriginType(editorType)
  setCommonConfig(commonConfig)
}

export const __initMainFrame = (extraNamespacesArg = [], editorType = '') => {
  initEditor()
  extraNamespaces = extraNamespacesArg
  setEditorOriginType(editorType)
}

export const __setAppExportedAPIs = workerSdk.setAppExportedApis
export const __unsetAppExportedAPIs = workerSdk.unsetAppExportedApis

function isExtensionContextOptions(contextOptions) {
  return (
    contextOptions.extensionName !== undefined &&
    contextOptions.appDefinitionId === undefined
  )
}

function convertContextToAppData(contextOptions) {
  if (isExtensionContextOptions(contextOptions)) {
    const {extensionName} = contextOptions
    return {
      appDefinitionId: extensionName,
      context: SDK_CONTEXT.EXTENSION,
    }
  }

  return {
    appDefinitionId: contextOptions.appDefinitionId,
    applicationId: contextOptions.applicationId,
    context: SDK_CONTEXT.DEFAULT,
  }
}

export function getBoundedSDK(contextOptions = {}) {
  const appData = convertContextToAppData(contextOptions)
  return editorSDK(appData, extraNamespaces)
}

let API = {}
if (!(isWebWorker() || isNodeWorker()) && !isMainFrame()) {
  initEditor()
  const extraNamespacesArg = getQueryParameter('extraNamespaces') || ''
  const editorType = getQueryParameter('editorType')
  setEditorOriginType(editorType)
  const appData = convertContextToAppData({
    applicationId: getQueryParameter('applicationId'),
    appDefinitionId: getQueryParameter('appDefinitionId'),
  })
  API = editorSDK(appData, extraNamespacesArg.split(','))
  initReceiver(API, {
    appDefinitionId: appData.appDefinitionId,
  })
}

export const addEventListener = API.addEventListener
export const removeEventListener = API.removeEventListener

export const document = API.document
export const editor = API.editor
export const appBuilder = API.appBuilder
export const appSettings = API.appSettings

export const panel = API.panel
export const preview = API.preview
export const ide = API.ide
export const selection = API.selection
export const environment = API.environment
export const userPreferences = API.userPreferences

export const vfs = API.vfs
export const pages = API.pages
export const routers = API.routers
export const controllers = API.controllers
export const components = API.components
export const history = API.history
export const tpa = API.tpa
export const menu = API.menu
export const pagesGroup = API.pagesGroup
export const language = API.language
export const siteSegments = API.siteSegments
export const siteMembers = API.siteMembers
export const theme = API.theme
export const fonts = API.fonts
export const info = API.info
export const responsiveLayout = API.responsiveLayout
export const errors = API.errors
export const wixapps = API.wixapps
export const accessibility = API.accessibility
