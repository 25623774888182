import {getAPI} from './privates/editorAPI'
import {AppData} from '@wix/editor-platform-sdk-types'

export function SDKAuthStrategy(appData: AppData) {
  /**
   * @doc AuthStrategy
   * @description Returns the authentication strategy to be used with Wix's public REST APIs.
   * @example
   * const authStrategy = await editorSDK.getAuthStrategy();
   * const client = createClient({
   *   auth: authStrategy,
   *   modules: {
   *     orders,
   *     products,
   *   },
   * });
   * client.products
   *   .queryProducts()
   *   .find()
   *   .then((res) => console.log(res))
   *   .catch((e) => console.log(e));
   * @returns A promise that is resolved with the auth strategy.
   */
  async function getAuthStrategy() {
    const api = await getAPI()
    const token = ''
    const options = {applicationId: appData.applicationId}

    return {
      getAuthHeaders: async () => ({
        headers: {
          Authorization: await api.document.info.getAppInstance(
            appData,
            token,
            options,
          ),
        },
      }),
    }
  }
  return {getAuthStrategy}
}
