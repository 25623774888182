import {getAPI} from '../../privates/editorAPI'
import {
  ComponentClientSpecMapEntry,
  PageRef,
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  IGetDataByAppDef,
} from '@wix/editor-platform-sdk-types'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc App
   * @example
   * const ECOM_APP_DEF_ID = 'mock-app-def-id'
   * const appData = await editorSDK.document.tpa.app.getDataByAppDefId(token, ECOM_APP_DEF_ID);
   * @param token - app token - not in use
   * @param appDefinitionId - The appDefinitionId of the app.
   * @description Returns an app's client spec data, including component data, without document information.
   * @returns The entire app [clientspecmap](../articles/glossary.md#clientspecmap) entry.
   */
  function getDataByAppDefId(
    token,
    appDefinitionId: string,
  ): Promise<IGetDataByAppDef> {
    return getAPI().then((api) => {
      return api.document.tpa.app.getDataByAppDefId(
        appData,
        token,
        appDefinitionId,
      )
    })
  }

  /**
   * @doc App
   * @example
   * const applicationId = 1232
   * const ecomComps = await editorSDK.document.tpa.app.getAllCompsByApplicationId(token, applicationId);
   * @param token - app token - not in use
   * @param applicationId - The applicationId of the app (The number assigned in the specific meta site).
   * @description returns a list of all components of the specified application ID(s).
   * @returns a promise to all the components of the specified app
   */
  function getAllCompsByApplicationId(
    token,
    applicationId: number | number[],
  ): Promise<ComponentClientSpecMapEntry[] | null> {
    return getAPI().then((api) => {
      return api.document.tpa.app.getAllCompsByApplicationId(
        appData,
        token,
        applicationId,
      )
    })
  }

  /**
   * @doc App
   * @example
   * await editorSDK.document.tpa.app.refreshApp('token');
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - applicationId: The ID of the application instance to refresh.
   * @description Refreshes all of the TPA iframes of the selected app.
   * @returns Promise resolved with undefined once the iframes's URLS change.
   */
  function refreshApp(
    token: string,
    options: ContextAwareOptions<Context, void, {applicationId: number}>,
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.tpa.app.refreshApp(appData, options)
    })
  }

  /**
   * @doc App
   * @example
   * await editorSDK.document.tpa.app.delete(token);
   * @param token - app token - not in use
   * @param options (Required only in Editor Extensions context):
   * - applicationId: The ID of the application instance to delete.
   * @description Removes a TPA application
   * @returns A Promise that is resolved once the application is removed.
   */
  function deleteApp(
    token,
    options: ContextAwareOptions<Context, void, {applicationId: number}>,
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.tpa.app.delete(appData, options)
    })
  }

  /**
   * @doc App
   * @example
   * const {instanceId} = await editorSDK.document.tpa.app.add(token, {
   *   appDefinitionId
   * });
   * @param token - app token - not in use
   * @param options -
   * - appDefinitionId: The appDefinitionId of the app to add.
   * - managingAppDefId: Optional. The appDefinitionId of the app that can manage the pages added by this function. For example, the ID of *Members*, which can manage other apps' pages.
   * @description Adds a new TPA to the site.
   * @returns The *instanceId* of the new app.
   * If the app has a section page, also returns a reference to the page, its pageUriSEO and its title.
   */
  function add(
    token,
    options: {appDefinitionId: string; managingAppDefId?: string},
  ): Promise<
    | {instanceId: string}
    | {
        instanceId: string
        pageRef: PageRef
        pageUriSEO: string
        title: string
      }
  > {
    return getAPI().then((api) => {
      return api.document.tpa.app.add(appData, token, options)
    })
  }

  return {
    getDataByAppDefId,
    getAllCompsByApplicationId,
    refreshApp,
    delete: deleteApp,
    add,
  }
}
