import {
  ContextAwareOptions,
  SDKDefaultContext,
  SDKContext,
  AppData,
  ComponentRef,
  CustomMenuData,
  BasicMenuItem,
} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../privates/apiWrapper'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  const menuOperationType = apiWrapper.OPERATION_TYPES.MENU

  /**
   * @doc Menu
   * @description This method creates a new custom menu that is related to the application
   * Note: Menu supports only 2 levels of menu items
   * @example
   * //creation of simple custom menu with default data structure and application appId
   * const menuItem :MenuItem = {
   *  id:'st',
   *  type: 'BasicMenuItem',
   *  label: 'labl',
   *  isVisible: true,
   *  isVisibleMobile: true,
   *  items: []
}
   * const menuId = await editorSDK.document.menu.create('token', {
   *  menuData: {
   *    items: [menuItem],
   *    name: 'some-name'
   *  },
   * })
   * @returns Promise that is resolved with menuId
   * @param token - app token - not in use
   * @param options -
   * - menuData: data item of the menu. Can contain items (an array of Basic menu items) and name.
   *  Note: appId is added automatically to the menuData
   * - customId: custom id to be used when menu is created
   * - applicationId (Required only in Editor Extensions context): An ID of the application to create a menu for.
   */
  function create(
    token: string,
    options: ContextAwareOptions<
      Context,
      {menuData?: {items: BasicMenuItem[]; name: string}; customId?: string},
      {applicationId: number}
    >,
  ): Promise<CustomMenuData> {
    return apiWrapper.dsSetter(
      {
        operationTypes: menuOperationType,
        waitingType: apiWrapper.WAITING_TYPES.NONE,
      },
      (api) => api.document.menu.create(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @description - This method should be used to connect the menu component to the menu data
   * @example await editorSDK.document.menu.connect('token', {
   *    menuCompPointer: {
   *        type: 'DESKTOP',
   *        id: 'idOfMenuCompToConnectTo'
   *    },
   *    menuId: 'CUSTOM_MENU_ID'
   * })
   * @returns Promise that returns undefined
   * @param token - app token - not in use
   * @param options -
   *  - menuCompPointer: the pointer of the menu component
   *  - menuId: the id of the menu data item to connect to the component
   */
  function connect(
    token: string,
    options: {menuCompPointer: ComponentRef; menuId: string},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: [options.menuCompPointer, {id: options.menuId}],
        operationTypes: menuOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS_AND_TYPE,
      },
      (api) => api.document.menu.connect(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @example await editorSDK.document.menu.update('token', {
   *    menuData: {
   *        name: 'new menu name'
   *    },
   *    menuId: 'CUSTOM_MENU_ID'
   * })
   * @description This method updates the menu with new menuData, can be used for example when order of menu items is changed, or menu name is changed
   * @returns Promise that returns undefined
   * @param token - app token - not in use
   * @param options -
   *  - menuData: new menu data - full object
   *  - menuId: the id of the menu to update
   */
  function update(
    token: string,
    options: {menuId: string; menuData: object},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: {id: options.menuId},
        operationTypes: menuOperationType,
        waitingType: [apiWrapper.WAITING_TYPES.TYPE],
      },
      (api) => api.document.menu.update(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @note `Classic Editor` `Editor X`
   * @description Adds an item to the menu.
   * @example await editorSDK.document.menu.addItem('token', {
   *    menuId: 'CUSTOM_MENU_ID',
   *    menuItem: {
   *        type: 'BasicMenuItem',
   *        items: []
   *    }
   * })
   * @returns Promise that returns undefined
   * @param token - app token - not in use
   * @param options -
   *  - menuItem: new item of type 'BasicMenuItem'
   *  - menuId: the id of the menu
   */
  function addItem(
    token: string,
    options: {menuId: string; menuItem: object},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: {id: options.menuId},
        operationTypes: menuOperationType,
        waitingType: [apiWrapper.WAITING_TYPES.TYPE],
      },
      (api) => api.document.menu.addItem(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @description This method returns the menu data corresponding to the given menuId
   * @example await editorSDK.document.menu.getById('token', {
   *    menuId: 'CUSTOM_MENU_ID'
   * })
   * @returns Promise that is resolved with menu data
   * @param token - app token - not in use
   * @param options -
   *  - menuId: the id of the menu
   */
  function getById(
    token: string,
    options: {menuId: string},
  ): Promise<CustomMenuData> {
    return apiWrapper.dsGetter(
      {
        operationTypes: menuOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.menu.getById(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @description This method permanently deletes the menu
   * @example await editorSDK.document.menu.remove('token', {
   *    menuId: 'CUSTOM_MENU_ID'
   * })
   * @returns Promise that returns undefined
   * @param token - app token - not in use
   * @param options -
   *  - menuId: the id of the menu to remove
   */
  function remove(token: string, options: {menuId: string}): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: {id: options.menuId},
        operationTypes: menuOperationType,
        waitingType: [apiWrapper.WAITING_TYPES.TYPE],
      },
      (api) => api.document.menu.remove(appData, token, options),
    )
  }

  /**
   * @doc Menu
   * @note `Classic Editor` `Editor X`
   * @description Returns the menuId of the main menu, if present. Otherwise, returns the menuID of the first custom menu on the menu list, or *null* when there are no menus.
   * @example const defaultMenuId = await editorSDK.document.menu.getDefaultMenuId('token')
   * @returns {Promise<string|null>} Promise that resolves with the return of a menuId or *null*.
   * @param token - app token - not in use
   */
  function getDefaultMenuId(token: string): Promise<string | null> {
    return apiWrapper.dsGetter(
      {
        operationTypes: menuOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.menu.getDefaultMenuId(appData, token),
    )
  }

  return {
    connect,
    update,
    addItem,
    getById,
    remove,
    create,
    getDefaultMenuId,
  }
}
